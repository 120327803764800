export default {
  BUTTON_PRIMARY_CLASS: 'btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',

  FACET_PANEL_CLASS: 'page-sidebar',
  PRODUCT_REPEATER_CLASS: 'halo-column halo-column-product',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  SEARCH_BOX_BUTTON_CLASS: 'btn-search icon-search',
  SEARCH_BOX_CLASS: 'input-group search-bar',
  SEARCH_BOX_INPUT_CLASS: 'input-group-field',

  GARAGE_BUTTON_CLASS: 'navUser-text-wrapper',
  GARAGE_CLASS: 'navUser-action custom',
  GARAGE_ICON: '<div class="garage-icon cm_icon_garage__background icon"></div>',
  GARAGE_SIZE: '<span class="garage-size countPill" key="garage-size">{{size}}</span>',

  VEHICLE_WIDGET_HOME_CLASS: 'container',
  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',
  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Vehicle',
  CLEAR_TEXT: '<rt-virtual template="icons/reset" />',
  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  RELATED_PRODUCT_CONTAINER_CLASS: 'container',
  RELATED_PRODUCT_TITLE_CONTAINER_CLASS: 'section-header text-left',
};
