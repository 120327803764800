
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-body' }, [this.vehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n        ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n      ');
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                            'className': 'index',
                            'key': '118'
                        }, this.index + 1) : null, _createElement.apply(this, [
                            'select',
                            {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.firstSelectedTerm ? this.firstSelectedTerm : '',
                                'aria-selected': this.selectedEntries.length ? true : false,
                                'disabled': this.disabled
                            },
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n        ', this.title, '\n      ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            _map(this.entries, repeatEntry1.bind(this))
                        ])));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__dialog cm_vehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear btn btn--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'cm_icon cm_icon-reset',
                    'height': '16px',
                    'viewBox': '0 0 16 16',
                    'key': '26330'
                }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:vehicleWidget"]